var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"elevation-0 transparent"},_vm._l((_vm.products),function(product){return _c('v-row',{key:product.id},[_c('HorizontalCard',_vm._b({staticClass:"mt-1"},'HorizontalCard',{
                   idx: product.id,
                   title: product.title,
                   price: product.price,
                   specialPrice: product.specialPrice,
                   sale: product.sale,
                   salePercent: product.salePercent,
                   description: product.description,
                   available: product.available,
                   source: product.images && product.images.length > 0 ? product.images[0] : '',
                   actions: {..._vm.actions, onHeartClick: _vm.onHeartClick, onAbacusClick: _vm.onAbacusClick, onCartClick: _vm.onCartClick, onProductView: _vm.onProductView, goToProduct: _vm.goToProduct},
                   favourite: _vm.favouriteIds.indexOf(product.id) !== -1,
                   compare: _vm.compareIds.indexOf(product.id) !== -1
                },false))],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }