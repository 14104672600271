var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-0",attrs:{"id":"category-products"}},[(_vm.hasFilters && _vm.window.innerWidth <= 1280 && _vm.productsGroups.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ProductFilter',_vm._b({},'ProductFilter',{filters: _vm.filters,
                             expands: true},false))],1)],1):_vm._e(),_c('v-scroll-y-transition',[(_vm.blocks && _vm.blocks.length > 0)?_c('v-row',[_c('v-col',{staticClass:"fill-height"},_vm._l((_vm.blocks),function(info,index){return _c('BlockInfo',{key:`${info['@Block']}-${index}`,attrs:{"info":info}})}),1)],1):_vm._e()],1),(_vm.productsGroups && _vm.productsGroups.length > 0)?[(_vm.hasFilters)?_c('v-row',{staticClass:"d-flex flex-row align-start justify-start"},[(_vm.window.innerWidth > 1280 && _vm.productsGroups.length > 0)?_c('v-col',{attrs:{"cols":"3"}},[(!_vm.withoutFilter)?_c('ProductFilter',_vm._b({},'ProductFilter',{filters: _vm.filters},false)):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.window.innerWidth > 1280 ? 9 : 12}},[_c('div',{ref:"productsColsRef"},[_c('ProductPresets',{staticClass:"mb-2",attrs:{"presets":this.presets,"expands":_vm.window.innerWidth <= 1280}}),_vm._l((_vm.productsGroups),function(productsGroup,$index){return [(_vm.presetsFilters.displayPages === 'grid')?_c('ProductsGrid',_vm._b({key:`products-grid-${$index}`,attrs:{"id":"product-grid"}},'ProductsGrid',{
                                    products: productsGroup,
                                    gridCols: _vm.gridCols,
                                    cardWidth: _vm.cardWidth,
                                    windowWidth: _vm.windowWidth,
                                    favouriteIds: _vm.favouriteIds,
                                    compareIds: _vm.compareIds
                    },false)):_vm._e(),(_vm.presetsFilters.displayPages === 'list')?_c('ProductsList',_vm._b({key:`product-list-${$index}`,attrs:{"id":"product-list"}},'ProductsList',{
                                    products: productsGroup,
                                    favouriteIds: _vm.favouriteIds,
                                    compareIds: _vm.compareIds
                    },false)):_vm._e()]}),(_vm.presetsFilters.displayFormat === 'Лента')?_c('infinite-loading',{attrs:{"identifier":_vm.infiniteId},on:{"infinite":_vm.infinityHandler}},[_c('v-card-title',{staticClass:"white--text",attrs:{"slot":"spinner"},slot:"spinner"},[_vm._v("Загрузка...")]),_c('v-card-title',{staticClass:"white--text",attrs:{"slot":"no-more"},slot:"no-more"},[_vm._v("Вы всё просмотрели. Отличная работа!")]),_c('v-card-title',{attrs:{"slot":"no-results"},slot:"no-results"})],1):_vm._e(),(_vm.presetsFilters.displayFormat === 'Постраничный')?[(_vm.currentPage < _vm.totalPages)?_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.totalPages},on:{"input":_vm.loadHandler},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()]:_vm._e()],2)])],1):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }