var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.products.length > 0)?_c('v-col',{staticClass:"px-0"},_vm._l((this.productRows),function(row){return _c('v-row',{key:`row-${row.idx}`,staticClass:"d-flex flex-row"},_vm._l((row.col),function(product){return _c('v-col',{key:`col-${product.id}`,staticClass:"d-flex flex-row",attrs:{"cols":row.col.length === _vm.gridCols ? 12/_vm.gridCols : 12/row.col.length}},[_c('ProductCard',_vm._b({style:({'width': '100%'})},'ProductCard',{
                 index: product.id,
                 product: product,
                 width: _vm.cardWidth,
                 actions: {goToProduct: _vm.goToProduct},
                 favourite: _vm.favouriteIds.indexOf(product.id) !== -1,
                 compare: _vm.compareIds.indexOf(product.id) !== -1},false))],1)}),1)}),1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }